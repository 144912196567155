import "./App.css";
import { useMemo } from "react";
import React, { useState } from "react";
import Minter from "./Minter";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";
import companyLogo from "./assets/images/bossylion-logo.png";

import bgspace from "./assets/images/layers/Background/backgound_space.jpg";

//import bgspace from "./assets/images/cakes/cake(1).jpg";

import bgai from "./assets/images/layers/Background/background_AIA Building.jpg";
import bgradiaion from "./assets/images/layers/Background/background_radiaion.jpg";
import bgrainbow from "./assets/images/layers/Background/background_rainbow.jpg";
import bgsky from "./assets/images/layers/Background/background_sky.jpg";
import bgwheel from "./assets/images/layers/Background/background_Wheel.jpg";

import cake_chocoicy from "./assets/images/layers/Cake/cake_chocoicy.jpg";
import cake_greentea from "./assets/images/layers/Cake/cake_greentea.jpg";
import cake_orange from "./assets/images/layers/Cake/cake_orange.jpg";
import cake_pinkchoco from "./assets/images/layers/Cake/cake_pinkchoco.jpg";
import cake_rainbow from "./assets/images/layers/Cake/cake_rainbow.jpg";
import cake_redvelvet from "./assets/images/layers/Cake/cake_redvelvet.jpg";

import topping_greycat from "./assets/images/layers/Toppings/topping_greycat.jpg";
import topping_pinkalpaca from "./assets/images/layers/Toppings/topping_pinkalpaca.jpg";
import topping_rainbow from "./assets/images/layers/Toppings/topping_rainbow Alpaca.jpg";
import topping_wine_bottle from "./assets/images/layers/Toppings/topping_wine.jpg";
import topping_wine_glass from "./assets/images/layers/Toppings/topping_wine_glass.jpg";
import topping_yellowalpaca from "./assets/images/layers/Toppings/topping_yellowalpaca.jpg";
import topping_yellowcat from "./assets/images/layers/Toppings/topping_yellowcat.jpg";

import topping_cherry from "./assets/images/layers/Toppings/topping_cherry.jpg";

import topping_lollipop from "./assets/images/layers/Toppings/topping_lollipop.jpg";






import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { Container, Snackbar } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";

import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { SiDiscord } from "react-icons/si";
import { BsInstagram } from "react-icons/bs";
import Button from "@material-ui/core/Button";

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 40,
    alignItems: "center",
    padding: theme.spacing(0, 2),
  },
  fixedWidthContainer: {
    maxWidth: "240px",
    backgroundColor: "red",
  },
  titleSpacing: {
    marginRight: theme.spacing(2),
  },
  container: {
    display: "flex",
    "&>div": {
      flexGrow: "1",
    },
  },
}));

const candyMachineId = "ChKPyTEaq8aQG1DRLsV45CdaPiXiRpYaJXiTBAVYyD32"
  ? new anchor.web3.PublicKey("ChKPyTEaq8aQG1DRLsV45CdaPiXiRpYaJXiTBAVYyD32")
  : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const App = () => {
  const [selectedCategory, setSelectedCategory] = useState("background");
  const endpoint = useMemo(() => clusterApiUrl(network), []);
  const classes = useStyles();

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSolletWallet(),
      getMathWallet(),
    ],
    []
  );

 const iframe1= `<iframe width="100%" height="315" src="https://www.youtube.com/embed/mg1vI9y0rqM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
 const iframe2=`<iframe width="100%" height="315" src="https://www.youtube.com/embed/f5OjfZi1RUs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
 const iframe3=`<iframe width="100%" height="315" src="https://www.youtube.com/embed/rgVU_bzn7bM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;


  const [featureCollection] = useState([
    {
      id: 1,
      category: "background",
      itemList: [
        {
          img: bgspace,
          trait: "Space",
          occurrence: "26%",
        },
        // {
        //   img: bgai,
        //   trait: "Building",
        //   occurrence: "0.9%",
        // },
        {
          img: bgradiaion,
          trait: "Radiaion",
          occurrence: "16%",
        },
        {
          img: bgrainbow,
          trait: "Rainbow",
          occurrence: "14%",
        },
        {
          img: bgsky,
          trait: "Sky",
          occurrence: "26%",
        },
        {
          img: bgwheel,
          trait: "Wheel",
          occurrence: "18%",
        },
      ],
    },
    {
      id: 2,
      category: "cake",
      itemList: [
        {
          img: cake_chocoicy,
          trait: "Chocoicy",
          occurrence: "20%",
        },
        {
          img: cake_greentea,
          trait: "Green Tea",
          occurrence: "10%",
        },
        {
          img: cake_orange,
          trait: "Orange",
          occurrence: "20%",
        },
        {
          img: cake_pinkchoco,
          trait: "Pink Chocolate",
          occurrence: "20%",
        },
        {
          img: cake_rainbow,
          trait: "Rainbow",
          occurrence: "10%",
        },
        {
          img: cake_redvelvet,
          trait: "Redvelvet",
          occurrence: "20%",
        },
      ],
    },
    {
      id: 3,
      category: "toppings",
      itemList: [
        {
          img: topping_greycat,
          trait: "Grey Cat",
          occurrence: "11%",
        },
        {
          img: topping_yellowcat,
          trait: "Yellow Cat",
          occurrence: "17%",
        },
        {
          img: topping_pinkalpaca,
          trait: "Pink Alpaca",
          occurrence: "16%",
        },
        {
          img: topping_rainbow,
          trait: "rainbow Alpaca",
          occurrence: "10%",
        },
        {
          img: topping_yellowalpaca,
          trait: "Yellow Alpaca",
          occurrence: "11%",
        },

        {
          img: topping_wine_glass,
          trait: "Wine Glass",
          occurrence: "12%",
        },
        {
          img: topping_wine_bottle,
          trait: "Wine Bottle",
          occurrence: "11%",
        },
        {
          img: topping_cherry,
          trait: "Cherry",
          occurrence: "6%",
        },
        {
          img: topping_lollipop,
          trait: "Lollipop",
          occurrence: "6%",
        },
      ],
    },
  ]);

  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }

  return (
    <div>
      <div id="mobileNavContainer" className="mobile-nav">
        <div className="mobile-nav-close-button">
          <img src="/icons/close.svg" alt="" onClick={toggleMenu} />
        </div>
        <ul>
          <li>
            <img className="mobile-nav-logo" src={companyLogo} alt="" />
          </li>
          <li>
            <a href="/#minting" onClick={toggleMenu}>
              Minting
            </a>
          </li>

          <li>
            <a href="/#about" onClick={toggleMenu}>
              About
            </a>
          </li>

          <li>
            <a href="/#Rarity" onClick={toggleMenu}>
              Rarity
            </a>
          </li>

          <li>
            <a href="/#faq" onClick={toggleMenu}>
              FAQ
            </a>
          </li>
          <li>
            <div className="social-icons">
              <a
                href="https://www.instagram.com/bossy.lion/"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram />
              </a>
            </div>
          </li>
          <li>
            <div className="social-icons">
              <a
                href="https://discord.com/invite/gYCwykZ9Cf"
                target="_blank"
                rel="noreferrer"
              >
                <SiDiscord />
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-button" onClick={toggleMenu}>
        <img src="/icons/menu.svg" alt="" />
      </div>
      <nav>
        <div className="nav-container">
          <a href="https://bossylion.com/" target={"_blank"}>
            <img className="nav-logo" src={companyLogo} alt="" />{" "}
          </a>
          <a className="hide-800" href="/#minting">
            Minting
          </a>
          <a className="hide-800" href="/#about">
            About
          </a>
          <a className="hide-800" href="/#Rarity">
            Rarity
          </a>
          <a className="hide-800" href="/#faq">
            FAQ
          </a>
          <div className="social-icons hide-800">
            <a
              href="https://www.instagram.com/bossy.lion/"
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram />
            </a>
            <a
              href="https://discord.com/invite/gYCwykZ9Cf"
              target="_blank"
              rel="noreferrer"
            >
              <SiDiscord />
            </a>
          </div>
        </div>
      </nav>
      <div className="content-wrapper " style={{ marginTop: "20px" }}>
        <header className="special-background" id="minting">
          <div style={{ padding: "0 24px 0 24px 0" }}>
            <h3 className="text-white-color">Welcome To Bossy Lion</h3>
            <h1 className="text-white-color pb-3">
              Farewell CupCake Collection
            </h1>

            <Player
              autoplay
              loop
              controls={false}
              src="https://assets10.lottiefiles.com/private_files/lf30_mgvq0mek.json"
              style={{ height: "200px", width: "200px" }}
            ></Player>
          </div>
          <div>
            <ThemeProvider theme={theme}>
              <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallets} autoConnect>
                  <WalletDialogProvider>
                    <Minter
                      candyMachineId={candyMachineId}
                      connection={connection}
                      startDate={startDateSeed}
                      txTimeout={txTimeout}
                      rpcHost={rpcHost}
                    />
                  </WalletDialogProvider>
                </WalletProvider>
              </ConnectionProvider>
            </ThemeProvider>
          </div>
        </header>

        <div id="about" className="container">
          <h1 className="pb-3">About</h1>

          <p>
            I am heartbroken to miss seeing many work friends due to the COVID
            situation. Clich&eacute;, but I know our path will meet again!
          </p>
          <p>
            <br />
            But why wait to see in the real world when our path will lead to a
            high-five in the metaverse? I know there is a learning curve, but
            you are smart!
          </p>
          <p>
            <br />I have decided to force-upgrade your web tools with my own
            farewell cakes. With this exercise, you will get your crypto wallet,
            cryptocurrency, Brian&rsquo;s nft (not sure about any re-sell
            value). <br />
            Oh, I also wish you to experience why people chases NFTs:
          </p>
          <ol>
            <li>Only 150 cakes were made</li>
            <li>The private link was sent to &gt;300 work friends</li>
            <li>
              The cake is free (with gas fee charged to receiver &lt;HK$1 by
              block chain- I wish I could pay)
            </li>
            <li>
              Anyone with this link can get (aka- mint) as many cakes as he/ she
              wishes before all gone
            </li>
            <li>Bring your cake to the metaverse when we meet</li>
            <li>
              Visit the hosting site{" "}
              <a href="https://www.Bossylion.com">https://www.Bossylion.com</a>,
              a live nft project I have been working AFTER OFFICE HOURS.
              Brian&rsquo;s nft description will shoutout for Bossy Lion.
            </li>
          </ol>
        </div>

        <div id="Rarity" className="container">
          <h1 className="pb-3">Rarity</h1>

          <Grid container className={classes.root}>
            <Grid md={3} xs={12}>
              <ul className="RarityList">
                <li>
                  <Button
                    variant="text"
                    onClick={() => setSelectedCategory("background")}
                  >
                    Background
                  </Button>
                </li>
                <li>
                  <Button
                    variant="text"
                    onClick={() => setSelectedCategory("cake")}
                  >
                    Cake
                  </Button>
                </li>

                <li>
                  <Button
                    variant="text"
                    onClick={() => setSelectedCategory("toppings")}
                  >
                    Toppings
                  </Button>
                </li>
              </ul>
            </Grid>
            <Grid md={9} xs={12} item className="gallery-container">
              {featureCollection
                .filter((catItem) => catItem.category === selectedCategory)
                .map((categoryItem) =>
                  categoryItem.itemList.map((item, index) => {
                    return <SliderItem item={item} />;
                  })
                )}
            </Grid>
          </Grid>
        </div>

        <div id="faq" className="container faq">
          <h1 style={{ padding: "0 0 24px 0" }}>FAQ</h1>
          <div>
            <h4>How to mint?</h4>
            <p>
              You will need a wallet (phantom) and crypto (solana) before
              connecting the wallet to the minting button. If it does not work
              on mobile web, please use laptop or within phantom app (see videos
              below)
            </p>
            <div dangerouslySetInnerHTML={{ __html:iframe3} } />

            <div dangerouslySetInnerHTML={{ __html:iframe2} } />
            <div dangerouslySetInnerHTML={{ __html:iframe1} } />


            <hr />
          </div>

          <div>
            <h4>How many cakes are available </h4>
            <p>Total of 150 was created. 1 already used as testing</p>

            <hr />
          </div>

          <div>
            <h4>What if they are all gone</h4>
            <p>
              Sorry but the each nft is unique with limited supply. Come find me
              in metaverse and see everyone else brag about it XD
            </p>

            <hr />
          </div>
          <div>
            <h4>What is Bossy Lion</h4>
            <p>
              A life NFT project I am working on during spare time. Bossy Lion
              is an exclusive NFT community, inspired by Hong Kong's local
              traditions and culture. Embrace the "Lion" spirit and our bossy
              personalities! The project has acquired a strong community and
              will enter the metaverse soon. <a href="https://www.bossylion.com/">https://www.bossylion.com</a>
            </p>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

const SliderItem = (props: any) => (
  <div className="sc-author-box style-2">
    <div className="author-avatar">
      <img src={props.item.img} alt="Bossy Lion NFT" className="avatar" />
      {/* <div className="badge"></div> */}
    </div>
    <div className="author-infor">
      <h5 style={{ textTransform: "capitalize" }}>{props.item.trait}</h5>
      <span className="price">{props.item.occurrence}</span>
    </div>
  </div>
);





export default App;
